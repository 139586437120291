<template>
  <CabinetPage :preloader="preloader">
    <div class="mx-cabinet-page-header">
      <h4 class="mx-title">Dashboard</h4>
<!--      <div class="mx-actions">-->
<!--        <div class="dropdown">-->
<!--          <a href="#" class="dropdown-toggle mx-dropdown" id="actions" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--            <ph-dots-three-vertical :size="22" />-->
<!--          </a>-->
<!--          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="actions">-->
<!--            <li>-->
<!--              <a class="dropdown-item mx-dropdown-item" href="#">-->
<!--                <ph-plus :size="14" />-->
<!--                <span>Neuer standort</span>-->
<!--            </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a class="dropdown-item mx-dropdown-item" href="#">-->
<!--                <ph-plus :size="14" />-->
<!--                <span>Neue Firma</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="row">
      <div class="mx-list_card">
        <a href="#" class="mx-card">
          <h5 class="mx-title_border">
<!--            <ph-buildings :size="24" />-->
            <!--            <span>Anschrift</span>-->
          </h5>
          <div class="mx-list_element-info">
<!--            <strong>Anschrift</strong>-->
<!--            <div>-->
<!--              <span>Maxxidom Kino GmbH<br>Münster-str 420,<br>40470 Düsseldorf</span>-->
<!--            </div>-->
          </div>
        </a>
      </div>
    </div>

  </CabinetPage>
</template>

<script>

import { PhBuildings, PhIdentificationCard, PhPlus, PhDotsThreeVertical  } from "phosphor-vue";
import CabinetPage from "@/components/main/CabinetPage";

export default {
  components: {CabinetPage, PhBuildings, PhIdentificationCard, PhPlus, PhDotsThreeVertical},
  data() {
    return {
      preloader: true,
    }
  },
  mounted() {
    setTimeout(this.preload, this.options.preload_time);
  },
  methods: {
    preload() {
      this.preloader = false;
    }
  }
}
</script>
